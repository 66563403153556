import React from "react"
import tw, { styled } from 'twin.macro';

const BubbleWrap = styled.div`
${tw`p-4 absolute z-10 pb-8`};
white-space: pre-wrap;
${({color})=>{
  if(color == "blue"){
  return tw` right-0`
  }
  if(color == 'pink'){
    return tw`left-0`
  }
  return tw`right-0`
}}
`

const StyledBubble = styled.p`
  ${tw`p-4 rounded-md relative top-0 z-10 bg-green-200 leading-6 text-gray-800`};
  font-family: 'Raleway', sans-serif;
  white-space: pre-wrap;
  ${({color})=>{
    if(color == "blue"){
    return tw`bg-blue-200`
    }
    if(color == 'pink'){
      return tw`bg-pink-200`
    }
    return tw`bg-green-200`
  }}
`

const Author = styled.p`
${tw`leading-3 font-bold text-gray-800 m-0`};
font-family: 'Raleway', sans-serif;
`

const makeLines = (string)=>{
  console.log(string.split('\n').map(s=><p>{s}</p>))
}

const Bubble = ({color, text, onClick})=>{

  return <BubbleWrap color={color}>
    <StyledBubble  onClick={onClick} color={color}>
      {color === "pink" && <Author>Paola:</Author>}
      {color === "blue" && <Author>Laurence:</Author>}
      <p>{text}</p>
    </StyledBubble>
  </BubbleWrap>
}

export default Bubble