import React, { useState, useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { getUser, isLoggedIn } from "../services/auth"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Bubble from "../components/bubble"
import Frame from "../components/frame"
import Date from "../components/date"
import Container from '../components/container'
import tw, { styled } from "twin.macro"
import { BeakerIcon } from '@heroicons/react/solid'
import bg1 from '../graphics/cat1.png'
import bg2 from '../graphics/man.png'
import bg3 from '../graphics/man2.png'
import bg4 from '../graphics/man3.png'
import bg5 from '../graphics/cat3.png'
import bg6 from '../graphics/writing.png'
import bg7 from '../graphics/writing2.png'

const Buttons = styled.div`
  ${tw`flex justify-between`}
`
const IconWrap = styled.svg`
  height: 1.5rem;
  width: 1.5rem;
`

const ButtonsWrapper = styled.div`
  margin-top: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  z-index: 1000;
`

const BGWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  ${({bg})=>`background-image: url('${bg}');`}
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 50%;
  background-position: ${()=>{
    const seed = Math.random()
    if(seed <0.3){
      return `bottom right;`
    }
    if(seed<0.6){
      return `bottom`
    }
    else{
      return `bottom left`
    }
  }}
`

const PhotoFrame = styled.div`
  ${tw`rounded-md overflow-hidden`}
  img {content-fit: cover}
  .gatsby-image-wrapper-constrained{
    display: block;
  }
`

const StGatsbyImage = styled(GatsbyImage)`
  ${({opacity})=>opacity ? `opacity: 0.5;!important` : `opacity: 1;!important` }
  transition: all .2s ease-out;
`

export default function Home({ data }) {
  const pages = data.allPagesCsv.nodes
  const photos = data.allFile.nodes
  const [image, setImage] = useState((getImage(photos[0])))
  const [page, setPage] = useState(pages[0])
  const [pageN, setPageN] = useState(0)
  const nextPage = (p) => setPageN(p + 1)
  const prevPage = (p) => setPageN(p - 1)
  const [loading, setLoading] = useState(true)
  const [showText, setShowText] = useState(true)
  const [photoN, setPhotoN] = useState(false)
  const [photoVisible, setPhotoVisible] = useState(false)
  const [pageHasPhoto, setPageHasPhoto] = useState(false)
  const BGs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7]
  const [BG, setBG] = useState()
  useEffect(() => {
    console.log(pageHasPhoto, pageN)
    setPageHasPhoto(pages[pageN].photo === 'true')
    setShowText(true)
    setLoading(false)
    setPage(pages[pageN])
    setPhotoVisible(false)
    if(Math.random()<0.1){
      setBG(BGs[Math.floor(Math.random() * BGs.length)])
    } else {
      setBG(null)
    }
  }, [pageN])
  useEffect(() => {
    console.log('here', pageN, pages.slice(0,pageN+1).filter(p=>p.photo === "true"))
    if(photoN !== pages.slice(0,pageN+1).filter(p=>p.photo === "true").length){
      setPhotoN(pages.slice(0,pageN+1).filter(p=>p.photo === "true").length-1)
    }
  }, [pageN])
  useEffect(() => {
    setPhotoVisible(pages[pageN].photo === 'true')
  }, [pageN])
  
  return loading ? <div>loading</div> : (
    <div>
      <BGWrap bg={BG}/>
      <Date date={page.date}/>
      <PhotoFrame>
        {showText && <Bubble onClick={()=>setShowText(false)} text={page.text} color={page.color}/>}
        {photoVisible && <StGatsbyImage opacity={showText} image={getImage(photos[photoN])} alt={'hello'} />}
      </PhotoFrame>
      {pageHasPhoto ? <ButtonsWrapper>
        {showText ? <Buttons><div onClick={()=>setShowText(false)}><IconWrap xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#900C3F">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </IconWrap></div>
          <div onClick={()=>setShowText(false)}><IconWrap xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#900C3F">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </IconWrap></div>
        </Buttons> : <Buttons>
          {pageN > 0 ? <div onClick={() => prevPage(pageN)}><IconWrap xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
        </IconWrap></div> : <div></div>}
          {!showText && <div onClick={()=>setShowText(true)}><IconWrap xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </IconWrap></div>}
          {pageN < pages.length - 1 ? <div onClick={() => nextPage(pageN)}><IconWrap xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </IconWrap></div> : <div></div>}
        </Buttons>
        }
      </ButtonsWrapper> : 
        <ButtonsWrapper>
          <Buttons>
          {pageN > 0 ? <div onClick={() => prevPage(pageN)}><IconWrap xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
        </IconWrap></div> : <div></div>}
          {!showText && <div onClick={()=>setShowText(true)}><IconWrap xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
        </IconWrap></div>}
          {pageN < pages.length - 1 ? <div onClick={() => nextPage(pageN)}><IconWrap xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
        </IconWrap></div> : <div></div>}
        </Buttons>
        </ButtonsWrapper>
      }
    </div>
    // <Layout>
    //   <h1>Hello {isLoggedIn() ? getUser().name : "world"}!</h1>
    //   <p>
    //     {isLoggedIn() ? (
    //       <Container>
    //         {image && <GatsbyImage image={getImage(photos[1])} alt={'hello'}/>}
    //         <Frame>
    //           <Date/>
    //           {/* <Bubble text={page.text}/> */}
    //           hello
    //           lolo
    //         </Frame>
    //         <Buttons>
    //           <div onClick={()=>prevPage(pageN)}>prev</div>
    //           <div onClick={()=>nextPage(pageN)}>next</div>
    //         </Buttons>
    //       </Container>
    //     ) : (
    //       <>
    //         You should <Link to="/app/login">log in</Link> to see restricted
    //         content
    //       </>
    //     )}
    //   </p>
    // </Layout>
  )
}

export const data = graphql`
query MyQuery {
  allPagesCsv {
    nodes {
      text
      date
      color
      photo
    }
  }
  allFile(sort: {fields: relativePath}, filter: {relativePath: {regex: "/photos/"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            width: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
}

`