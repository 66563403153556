import React from "react"
import tw, { styled } from 'twin.macro';
import moment from 'moment'


const StyledDate = styled.p`
  ${tw`p-0 text-sm text-right`}
  font-family: 'Raleway';
`

const Date = ({ date }) => {
  moment.locale('fr')
  return <StyledDate>
      {moment(date, "DD/MM/YYYY").format('dddd, Do MMMM YYYY')}
  </StyledDate>
}

export default Date