import React from "react"
import tw, { styled } from 'twin.macro';

const StyledContainer = styled.div`
  ${tw`p-4 rounded-md border border-black border-solid`}
`

const Container = ({children})=>{
  return <StyledContainer>
    ciao
    {children}
  </StyledContainer>
}

export default Container