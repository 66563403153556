import React from "react"
import tw, { styled } from 'twin.macro';
import Date from './date'
import Bubble from './bubble'
import Img from "gatsby-image"

const StyledFrame = styled.div`
  ${tw`p-2 pb-8`}
`

const InnerFrame = styled.div`
 ${tw` rounded-md border border-black border-solid`}
`

const Frame = ({ date, photo, text, children }) => {
  return <StyledFrame>
    <Date>
      {date}
    </Date>
    <InnerFrame>
      <Bubble text={text}/>
      {children}
    </InnerFrame>
  </StyledFrame>
}

export default Frame